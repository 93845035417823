define(['app', 'componentHelper', 'responsivePagination', '$window',  'accessibilityFocusHelper'], (app, componentHelper, pagination, $window,  accessibilityFocusHelper) => {

  const foundationFinderMultiBrandResults = () => {
    const component = {};
    const trackingEventCategory = 'Foundation Finder';
    const trackingEventAction = 'Final Stage';

    component.config = {
      selectors: {
        element: '[data-component=foundationFinderMultiBrandResultsWrapper]',
        lighterMatchButton: '[data-lighter-match]',
        bestMatchButton: '[data-best-match]',
        darkerMatchButton: '[data-darker-match]',
        lighterMatches: '[data-matches=lighter]',
        bestMatches: '[data-matches=best]',
        darkerMatches: '[data-matches=darker]',
        sample: '[data-has-sample]',
        product: '.productListProducts_product',
        productBlock: '.productBlock ',
        productBlockLink: '.productBlock_link',
        noSamplesMessage: '.foundationFinderMultiBrandResults_NoSamples',
        noProductsMessage: '.foundationFinderMultiBrandResults_noProducts',
        samplesCheckbox: '#samplesCheckbox',
        productsWithoutSample: 'li.productListProducts_product:not([data-has-sample])',
        loaderOverlay: '.responsiveProductListPage_loaderOverlay',
        title: '.foundationFinderMultiBrandResults_title',
        errorMessage: '.foundationFinderMultiBrandResults_errorMessage',
        quickBuyButton: '.productQuickbuySimple',
        wishlistButton: '.productAddToWishlist_button_default'
      },
      attributes: {
        hide: 'data-hide',
        show: 'data-show',
        selected: 'data-selected',
        productId: 'rel'
      },
      classes: {
        hide: 'hide'
      },
      channels: {
        showResults: 'foundationFinder/results',
      }
    };

    component.init = (element, userDriven, isCardFlow) => {
      component.element = element;
      component.lighterMatchButton = component.element.querySelector(component.config.selectors.lighterMatchButton);
      component.bestMatchButton = component.element.querySelector(component.config.selectors.bestMatchButton);
      component.darkerMatchButton = component.element.querySelector(component.config.selectors.darkerMatchButton);
      component.lighterMatches = component.element.querySelector(component.config.selectors.lighterMatches);
      component.bestMatches = component.element.querySelector(component.config.selectors.bestMatches);
      component.darkerMatches = component.element.querySelector(component.config.selectors.darkerMatches);
      component.samplesCheckbox = component.element.querySelector(component.config.selectors.samplesCheckbox);
      component.samplesCheckboxLabel = component.element.querySelector(component.config.selectors.samplesCheckboxLabel);
      component.currentMatches = component.bestMatches;
      component.overlay = component.element.querySelector(component.config.selectors.loaderOverlay);
      component.title = component.element.querySelector(component.config.selectors.title);
      component.errorMessage = component.element.querySelector(component.config.selectors.errorMessage);

      if (!userDriven) {
        app.subscribe(component.config.channels.showResults, component.showResults);
        app.subscribe('responsiveProductListPage/update', component.paginationUpdated);
      } else {
        component.element.classList.remove(component.config.classes.hide);
        accessibilityFocusHelper.focusAndScroll(component.title);
        componentHelper.reloadAllComponents(component.element);
        component.pagination = new pagination();
        component.pagination.init(component.element);
        component.isCardFlow = isCardFlow;
        component.handleResultMatches();
        component.bind()
      }
    };

    component.paginationUpdated = ({pageNumber: value}) => {
      component.overlay.setAttribute(component.config.attributes.show, 'true');
      const samplesOnly = component.samplesCheckbox.checked;
      app.ajax.post({
        url: `/matchesNoCard.foundationSearch?pageNumber=${value}&facetFilters=${component.facetFilters}&samplesOnly=${samplesOnly}`,
        success: component.showNoCardFlowResults,
        error: component.onError
      });
    };

    component.bind = () => {
      component.lighterMatchButton && component.lighterMatchButton.addEventListener('click', component.showLighterMatches);
      component.bestMatchButton && component.bestMatchButton.addEventListener('click', component.showBestMatches);
      component.darkerMatchButton && component.darkerMatchButton.addEventListener('click', component.showDarkerMatches);
      component.samplesCheckbox && component.samplesCheckbox.addEventListener('change', component.handleSamplesCheckbox);
      component.samplesCheckbox.parentElement && component.samplesCheckbox.parentElement.addEventListener('keydown', component.handleKeyDownEventOnCheckbox);
    };

    component.handleResultMatches = () => {
      if(component.isCardFlow) {
        component.productsWithoutSample = component.element.querySelectorAll(component.config.selectors.productsWithoutSample);
        component.setUpCurrentMatch(component.currentMatches);
      } else {
        let products = component.element.querySelectorAll(component.config.selectors.product);
        if(products.length === 0) {
          component.handleErrorMessagesNoCardFlow();
        }
      }
    };

    component.onError = () => {
      component.overlay.setAttribute(component.config.attributes.show, 'false');
      component.errorMessage.classList.remove(component.config.classes.hide);
    };

    component.showResults = (response, facetFilters, isCardFlow) => {
      if (response) {
        component.element.innerHTML = response;
        component.init(component.element, true, isCardFlow);
        component.facetFilters = facetFilters;
        const trackingEventCategoryWithCardFlow = isCardFlow ? `${trackingEventCategory} Yes` : `${trackingEventCategory} No`;

        component.productBlockList = component.element.querySelectorAll(component.config.selectors.productBlock);
        component.productBlockList && component.productBlockList.forEach(productBlock => {
          const productId = productBlock.getAttribute(component.config.attributes.productId);

          const wishListButton = productBlock.querySelector(component.config.selectors.wishlistButton);
          wishListButton && wishListButton.addEventListener('click', () => {
            app.publish('tracking/record', trackingEventCategoryWithCardFlow, trackingEventAction, `Add to wishlist ${productId}`);
          })
          const quickBuyButton = productBlock.querySelector(component.config.selectors.quickBuyButton);
          quickBuyButton && quickBuyButton.addEventListener('click', () => {
            app.publish('tracking/record', trackingEventCategoryWithCardFlow, trackingEventAction, `Quick buy ${productId}`);
          })
          const productBlockLink = productBlock.querySelectorAll(component.config.selectors.productBlockLink);
          productBlockLink && productBlockLink.forEach(link => {
            link.addEventListener('click', () => {
              app.publish('tracking/record', trackingEventCategoryWithCardFlow, trackingEventAction, `Product view ${productId}`);
            })
          })
        })
      } else {
        console.error('ERROR: The foundation finder multi brand results has returned empty');
      }
    };

    component.showNoCardFlowResults = (response) => {
      component.showResults(response, component.facetFilters, false);
      component.overlay.setAttribute(component.config.attributes.show, 'false');
    };

    component.handleErrorMessagesNoCardFlow = () => {
      const noAvailableSamplesMessage = component.currentMatches.querySelector(component.config.selectors.noSamplesMessage);
      const errorMessageNoProducts = component.currentMatches.querySelector(component.config.selectors.noProductsMessage);

      if (component.samplesCheckbox.checked) {
        errorMessageNoProducts.classList.add(component.config.classes.hide);
        noAvailableSamplesMessage.classList.remove(component.config.classes.hide);
      } else {
        noAvailableSamplesMessage.classList.add(component.config.classes.hide);
        errorMessageNoProducts.classList.remove(component.config.classes.hide);
      }
    };

    component.handleErrorMessagesCardFlow = () => {
      const noAvailableSamplesMessage = component.currentMatches.querySelector(component.config.selectors.noSamplesMessage);
      const productWithSamples = component.currentMatches.querySelector(component.config.selectors.sample);

      if(component.samplesCheckbox.checked && !productWithSamples) {
        noAvailableSamplesMessage.classList.remove(component.config.classes.hide)
      } else {
        noAvailableSamplesMessage.classList.add(component.config.classes.hide);
      }
    };

    component.setUpCurrentMatch = (currentMatches) => {
      component.currentMatches = currentMatches;
      const doesCurrentMatchesHaveProducts = component.currentMatches.querySelector(component.config.selectors.products);
      if (doesCurrentMatchesHaveProducts) {
        component.areSamplesAvailable();
      }
    };

    component.areSamplesAvailable = () => {
      const noAvailableSamplesMessage = component.currentMatches.querySelector(component.config.selectors.noSamplesMessage);
      const productWithSamples = component.currentMatches.querySelector(component.config.selectors.sample);

      if(component.samplesCheckbox.checked && !productWithSamples) {
        noAvailableSamplesMessage.classList.remove(component.config.classes.hide)
      } else {
        noAvailableSamplesMessage.classList.add(component.config.classes.hide);
      }
    };

    component.handleSamplesCheckboxCardFlow = (event) => {
      component.handleErrorMessagesCardFlow();
      if((event && event.currentTarget.checked) || component.samplesCheckbox.checked) {
        component.productsWithoutSample.forEach(product => product.style.display = 'none');
      } else {
        component.productsWithoutSample.forEach(product => product.style.display = 'flex');
      }
    };

    component.handleSamplesCheckbox = (event) => {
      if(component.isCardFlow) {
        component.handleSamplesCheckboxCardFlow(event);
      } else {
        component.paginationUpdated({pageNumber: 1});
      }
    };

    component.handleKeyDownEventOnCheckbox = (event) => {
      if (event.key === ' ') {
        event.preventDefault();
        component.samplesCheckbox.checked = !component.samplesCheckbox.checked;
        component.handleSamplesCheckbox();
      }
    };

    component.setUpCurrentMatch = (currentMatches) => {
      component.currentMatches = currentMatches;
      let products = component.currentMatches.querySelectorAll(component.config.selectors.product);
      if (products.length > 0) {
        component.handleErrorMessagesCardFlow();
      }
    };

    component.showLighterMatches = () => {
      component.hideAllMatches();
      component.lighterMatches.setAttribute(component.config.attributes.hide, "false");
      component.lighterMatchButton.setAttribute(component.config.attributes.selected, "true");
      component.setUpCurrentMatch(component.lighterMatches);
    };

    component.showBestMatches = () => {
      component.hideAllMatches();
      component.bestMatches.setAttribute(component.config.attributes.hide, "false");
      component.bestMatchButton.setAttribute(component.config.attributes.selected, "true");
      component.setUpCurrentMatch(component.bestMatches);
    };

    component.showDarkerMatches = () => {
      component.hideAllMatches();
      component.darkerMatches.setAttribute(component.config.attributes.hide, "false");
      component.darkerMatchButton.setAttribute(component.config.attributes.selected, "true");
      component.setUpCurrentMatch(component.darkerMatches);
    };

    component.hideAllMatches = () => {
      component.lighterMatches.setAttribute(component.config.attributes.hide, "true");
      component.bestMatches.setAttribute(component.config.attributes.hide, "true");
      component.darkerMatches.setAttribute(component.config.attributes.hide, "true");

      component.lighterMatchButton.setAttribute(component.config.attributes.selected, "false");
      component.bestMatchButton.setAttribute(component.config.attributes.selected, "false");
      component.darkerMatchButton.setAttribute(component.config.attributes.selected, "false");
    };

    return component;
  };

  return foundationFinderMultiBrandResults;
});
